<template>
  <div style="padding-bottom: 32px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="广州对接" name="first">
        <guangzhou-list></guangzhou-list>
      </el-tab-pane>
      <el-tab-pane label="监测报告" name="second">
        <guangzhou-report-list></guangzhou-report-list>
      </el-tab-pane>
      <el-tab-pane label="维保记录" name="three">
        <guangzhou-maint-list></guangzhou-maint-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import GuangzhouList from "@/views/passport/guangzhou/GuangzhouList";
  import GuangzhouReportList from "@/views/passport/guangzhou/GuangzhouReportList";
  import GuangzhouMaintList from "@/views/passport/guangzhou/GuangzhouMaintList";

  export default {
    components: {GuangzhouList, GuangzhouReportList, GuangzhouMaintList},
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {},
  };
</script>

<style scoped></style>
