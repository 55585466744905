<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    append-to-body="true"
    :visible.sync="dialogVisible"
    :title="model.id?'编辑':'新增'"
    width="1000px"
    top="8vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      label-width="130px"
      :model="model">
      <div>
        <div class="vm-separate">
          <el-form-item v-if="model.id" label="维保工单号" prop="orderNo">
            <el-input v-model="model.orderNo" disabled :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div v-if="model.id"></div>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$l('workOrder.elevator','电梯')" prop="elevatorId" :rules="$rule.notNull">
            <el-input v-model="model.elevatorName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$refs.selectElevator.open('integrationKey:guangzhou')"></el-button>
            </el-input>
            <elevator-select ref="selectElevator" @select="onSelectElevator"></elevator-select>
          </el-form-item>
          <el-form-item label="注册代码" prop="registerNo" :rules="$rule.notNull">
            <el-input v-model="model.registerNo" disabled placeholder="请选择电梯"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="维保类型" prop="maintType" :rules="$rule.notNull">
            <el-select v-model="model.maintType" placeholder="请选择" clearable style="width: 100%">
              <el-option :value="1" label="半月保"></el-option>
              <el-option :value="2" label="季度保"></el-option>
              <el-option :value="3" label="半年保"></el-option>
              <el-option :value="4" label="年度保"></el-option>
              <el-option :value="5" label="按需维保"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用单位是否签名" prop="unitSign" :rules="$rule.notNull">
            <el-select v-model="model.unitSign" clearable style="width: 100%">
              <el-option :value="1" label="是"></el-option>
              <el-option :value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="维保开始时间" prop="maintStartTime" :rules="$rule.notNull">
            <el-date-picker
              v-model="model.maintStartTime"
              type="datetime"
              :placeholder="$t('common.pleaseSelect')"
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"></el-date-picker>
          </el-form-item>
          <el-form-item label="维保结束时间" prop="maintEndTime" :rules="$rule.notNull">
            <el-date-picker
              v-model="model.maintEndTime"
              type="datetime"
              :placeholder="$t('common.pleaseSelect')"
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"></el-date-picker>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="维保人员1" prop="maintainerName1" :rules="$rule.notNull">
            <el-input v-model="model.maintainerName1" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item label="维保人员2" prop="maintainerName2">
            <el-input v-model="model.maintainerName2" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="维保人员3" prop="maintainerName3">
            <el-input v-model="model.maintainerName3" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item label="维保人员4" prop="maintainerName4">
            <el-input v-model="model.maintainerName4" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import ElevatorSelect from "@/views/elevator/ElevatorSelect";

  export default {
    components: {ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        loading: false,
        model: {
          id: 0,
          orderNo: "",
          unitSign: "",
          elevatorName: "",
          elevatorId: "",
          registerNo: "",
          maintStartTime: "",
          maintEndTime: "",
          maintType: "",
          maintainerName1: "",
          maintainerName2: "",
          maintainerName3: "",
          maintainerName4: "",
        },
        maintTypeList: [],
        newStartTime: "",
        newEndTime: "",
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;

        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("OpenMaintOrder/" + this.model.id)
          .then(({data}) => {
            //this.$assign(this.model, data);
            this.model = Object.assign(this.model, data);
            this.newStartTime = this.model.maintStartTime;
            this.newEndTime = this.model.maintEndTime;
          });
      },
      onSelectElevator(row) {
        if (row.id !== this.model.elevatorId) {
          this.model.elevatorId = row.id;
          this.model.elevatorName = row.name;
          this.model.registerNo = row.deviceId;
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (!this.model.id) {
              this.model.maintStartTime = this.model.maintStartTime + ":00";
              this.model.maintEndTime = this.model.maintEndTime + ":00";
            }
            if (this.newStartTime) {
              if (this.newStartTime !== this.model.maintStartTime) {
                this.model.maintStartTime = this.model.maintStartTime + ":00";
              }
            }
            if (this.newEndTime) {
              if (this.newEndTime !== this.model.maintEndTime) {
                this.model.maintEndTime = this.model.maintEndTime + ":00";
              }
            }
            this.$http.save("OpenMaintOrder", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
