<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="OpenMaintOrder">
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.edit.open()">新增</el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="orderNo" label="维保工单号" width="200" align="center"></el-table-column>
      <el-table-column
        prop="registerNo"
        label="注册代码"
        width="200"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="maintType" label="维保类型" width="100px" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.maintType === 1">半月保</span>
          <span v-if="scope.row.maintType === 2">季度保</span>
          <span v-if="scope.row.maintType === 3">半年保</span>
          <span v-if="scope.row.maintType === 4">年度保</span>
          <span v-if="scope.row.maintType === 5">按需维保</span>
        </template>
      </el-table-column>
      <el-table-column prop="maintStartTime" label="维保开始时间" width="150px"></el-table-column>
      <el-table-column prop="maintEndTime" label="维保结束时间" width="150"></el-table-column>
      <el-table-column prop="unitSign" label="使用单位是否签名" align="center" show-overflow-tooltip>
        <template v-slot="scope">
          <span v-if="scope.row.unitSign === 1">是</span>
          <span v-if="scope.row.unitSign === 0">否</span>
        </template>
      </el-table-column>
      <el-table-column prop="maintainerName1" label="维保人员1" align="center"></el-table-column>
      <el-table-column prop="maintainerName2" label="维保人员2" align="center"></el-table-column>
      <el-table-column prop="maintainerName3" label="维保人员3" align="center"></el-table-column>
      <el-table-column prop="maintainerName4" label="维保人员4" align="center"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="150" align="center" fixed="right">
        <template v-slot="scope">
          <el-button type="text" @click="$refs.edit.open(scope.row.id)">编辑</el-button>
          <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <guangzhou-maint-edit ref="edit" @save-success="getList(-1)"></guangzhou-maint-edit>
    </vm-table>
  </div>
</template>
<script>
  import GuangzhouMaintEdit from "@/views/passport/guangzhou/GuangzhouMaintEdit";


  export default {
    components: {GuangzhouMaintEdit},
    data() {
      return {
        loading: false,
        showAside: false,
        search: {
          filter: "",
          maintType: "",
          passportKey: "guangzhou",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageIndex) {
        this.loading = true;
        this.$refs.vmTable.getList(pageIndex);
        this.loading = false;
      },
      edit(row) {

      },
      handleDelete(row) {
        this.$api.deleteById("OpenMaintOrder", row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
          }
        });
      },
    },
  };
</script>
